import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { addEmailCampaign, getFaqQuestions } from '../../utils/api';
import './HomepageHub_Vendor.css';
import medialogos from '../../assets/design/logos/medialogos.png';

import hub1 from '../../assets/design/homepagehub/hub1b.png';
import hub2 from '../../assets/design/homepagehub/softwarehub2.png';
import hub3 from '../../assets/design/homepagehub/hub3b.png';
import pattern2 from '../../assets/design/homepagehub/pattern2.png';
import vendor1 from '../../assets/design/homepagehub/vendor1.png';
import vendor2 from '../../assets/design/homepagehub/vendor2.png';
import blog1 from '../../assets/design/homepagehub/blog1.png';
import blog2 from '../../assets/design/homepagehub/blog2.png';
import blog3 from '../../assets/design/homepagehub/blog3.png';
import footer from '../../assets/design/homepagehub/footer.png';
import herocards from '../../assets/design/homepagehub/software/herocards.png';
import herobg from '../../assets/design/homepagehub/software/herobg.png';
import heroicon from '../../assets/design/homepagehub/vendor/heroicon_vendor.png';
import paymentlogos from '../../assets/design/homepagehub/software/paymentlogos.png';
import relevance from '../../assets/design/homepagehub/software/softwarehub_relevance.png';
import logobg from '../../assets/design/homepagehub/software/logo_bg.png';
import booking from '../../assets/design/homepagehub/software/booking.png';
import relevant from '../../assets/design/homepagehub/software/rel.png';
import heroimage from '../../assets/design/homepagehub/vendor/vendorhero.png';
import StartDiscovering from './StartDiscovering';
import token1 from '../../assets/design/homepagehub/vendor/lptoken1.png';
import token2 from '../../assets/design/homepagehub/vendor/lptoken2.png';
import token3 from '../../assets/design/homepagehub/vendor/lptoken3.png';
import token4 from '../../assets/design/homepagehub/vendor/lptoken4.png';
import iconCheck from '../../assets/design/homepagehub/vendor/Icon_check.png';
import pattern1 from '../../assets/design/homepagehub/vendor/vendor_pattern.png';
import ScrollToTop from '../../components/layout/ScrollToTop';

function HomepageHub_Vendor() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [faq,setFaq] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      
    }

    async function loadFaq(){
      let res = await getFaqQuestions('seller');

      if (res.success){
        setFaq(res.data);
      }
    }

    loadData();
    loadFaq();
  }, []);

  async function handleFaqClick(item){
    setSelectedFaq(item);
  }

  return (
    <div className='homepagehubvendor'>
      <ScrollToTop />
      <Helmet>
        <title>LeadrPro - Vendor Hub</title>
      </Helmet>

      <div className='homepagehubvendor__hero'>
        <div className='homepagehubvendor__hero-left'>
          <div className='homepagehubevents__hero-icon'>
            <img src={heroicon} alt='' /> <span className='homepagehubevents__hero-icon-text'>Vendor Hub</span>
          </div>

          <div className='homepagehub__hero-h1'>
            Drive revenue with<br/>
            high-profile leads
          </div>

          <div className='homepagehub__hero-subheader'>
            Gain free access to thousands of buyer leads who you can get in contact with and demo your product through our video calling feature.
          </div>

          <div className='homepagehub__hero-btns'>
            <button onClick={() => navigate('/signup/seller')} className='homepagehub__hero-btn homepagehub__hero-btn--primary'>
              Get started today
            </button>
          </div>

          <div className='homepagehubvendor__no-contracts'>
            No contract, no commitments.
          </div>
        </div>

        <div className='homepagehubvendor__hero-right'>
          <img src={heroimage} alt='' />
        </div>

        <div className='homepagehubvendor__hero-bg'>
          <img src={herobg} alt='' />
        </div>
      </div>

      <div className='homepagehubvendor__banner'>
        <div className='mb-30'>LeadrPro makes it easy for you to get sales!</div>

        <div>
          We do the hard work for you by matching you with ideal buyers who require a solution like yours for their business.
        </div>
      </div>

      <div className='homepagehubvendor__features'>
        <div className='homepagehub__hub-descriptions-pattern1'>
          <img src={pattern1} alt='' />
        </div>

          <div className='homepagehubvendor__features-text'>
            <h2>Don't waste resources on cold calling and emailing</h2>

            <p className='mt-30'>
            You can't afford to waste time and resources wishing for buyer leads. That's why LeadrPro is a must-have service that finds thousands of buyers and pairs their needs with your solution for a perfect match.
            </p>

            {/* <ul className='mt-20'>
              <li>Reveal and convert on warm buyer leads.</li>
              <li>We guarantee irreplaceable savings over your SDR.</li>
              <li>Take advantage of our free features to boost your sales.</li>
            </ul> */}
          </div>

          <div className='homepagehubvendor__features-list'>
            <div className='homepagehubvendor__features-list-item'>
              <img src={iconCheck} alt='' /><span className='ml-30'>Team management</span>
            </div>

            <div className='homepagehubvendor__features-list-item'>
              <img src={iconCheck} alt='' /><span className='ml-30'>Custom company profile page</span>
            </div>

            <div className='homepagehubvendor__features-list-item'>
              <img src={iconCheck} alt='' /><span className='ml-30'>Google calendar integration</span>
            </div>

            <div className='homepagehubvendor__features-list-item'>
              <img src={iconCheck} alt='' /><span className='ml-30'>Qualifier questions</span>
            </div>

            <div className='homepagehubvendor__features-list-item'>
              <img src={iconCheck} alt='' /><span className='ml-30'>High visibility among hundreds of qualified ICP's</span>
            </div>
          </div>
      </div>

      <div className='homepagehubvendor__pricing' id='pricing'>
          <h2>Demo Credit Plans and Pricing</h2>

          <div className='homepagehubvendor__pricing-description'>
            <div className='homepagehubvendor__pricing-description--header'>Save more and only pay for what you need</div>

            <div className='mt-10'>
              Whether your resource-saving needs are large or small, we're here to help you grow.Purchase demo credits and start meeting with buyers today.
            </div>
          </div>

          <div className='homepagehubvendor__pricing-cards'>
            <div className='homepagehubvendor__pricing-card-wrapper'>
            <div className='homepagehubvendor__pricing-card'>
              <div className='homepagehubvendor__pricing-card-name'>Pay per meeting</div>

              <div className='homepagehubvendor__pricing-card-description'>
                Great for testing the waters.
              </div>

              <div className='homepagehubvendor__pricing-card-cost'>
                <div className='homepagehubvendor__pricing-card-amount'>$500</div>
                <div className='mt-5'>/ 1 demo = 1 meeting</div>
              </div>

              <div className='homepagehubvendor__pricing-card-icon mt-20'>
                <img src={token1} alt='' />
              </div>

              <div className='homepagehubvendor__pricing-card-get-started'>
                <button onClick={() => navigate('/select-new-plan')}>Get started</button>
              </div>
            </div>
            </div>

            <div className='homepagehubvendor__pricing-card-wrapper'>
            <div className='homepagehubvendor__pricing-card'>
              <div className='homepagehubvendor__pricing-card-name'>Premium</div>

              <div className='homepagehubvendor__pricing-card-description'>
                Great to accelerate your growth.
              </div>

              <div className='homepagehubvendor__pricing-card-cost'>
                <div className='homepagehubvendor__pricing-card-amount'>$2000</div>
                <div className='mt-5'>/ 5 demo meetings</div>
              </div>

              <div className='homepagehubvendor__pricing-card-icon'>
                <img src={token2} alt='' />
              </div>

              <div className='homepagehubvendor__pricing-card-get-started'>
                <button onClick={() => navigate('/select-new-plan')}>Get started</button>
              </div>
            </div>

            <div className='homepagehubvendor__pricing-card-save'>
              Save 20% when purchasing more credits
            </div>
            </div>

            <div className='homepagehubvendor__pricing-card-wrapper'>
            <div className='homepagehubvendor__pricing-card homepagehubvendor__pricing-card--popular'>
              <div className='homepagehubvendor__pricing-card-name' style={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>Growth
                <span className='homepagehubvendor__pricing-card-popular'>Most popular</span>
              </div>

              <div className='homepagehubvendor__pricing-card-description'>
                Great for testing the waters.
              </div>

              <div className='homepagehubvendor__pricing-card-cost'>
                <div className='homepagehubvendor__pricing-card-amount'>$3000</div>
                <div className='mt-5'>/ 10 demo meetings</div>
              </div>

              <div className='homepagehubvendor__pricing-card-icon'>
                <img src={token3} alt='' />
              </div>

              <div className='homepagehubvendor__pricing-card-get-started'>
                <button className='popular' onClick={() => navigate('/select-new-plan')}>Get started</button>
              </div>
            </div>

            <div className='homepagehubvendor__pricing-card-save'>
              Save 40% when purchasing more credits
            </div>
            </div>

            <div className='homepagehubvendor__pricing-card-wrapper'>
            <div className='homepagehubvendor__pricing-card'>
              <div className='homepagehubvendor__pricing-card-name'>Enterprise</div>

              <div className='homepagehubvendor__pricing-card-description'>
                Perfect for high sales conversions
              </div>

              <div className='homepagehubvendor__pricing-card-cost'>
                <div className='homepagehubvendor__pricing-card-amount'>$5000</div>
                <div className='mt-5'>/ 20 demo meetings</div>
              </div>

              <div className='homepagehubvendor__pricing-card-icon'>
                <img src={token4} alt='' />
              </div>

              <div className='homepagehubvendor__pricing-card-get-started'>
                <button onClick={() => navigate('/select-new-plan')}>Get started</button>
              </div>
            </div>

            <div className='homepagehubvendor__pricing-card-save'>
              Save 50% when purchasing more credits
            </div>
            </div>
          </div>
      </div>

      {faq.length > 0 && (
      <div className='homepagehub__faq'>
        <div className='center homepagehub__faq-title'>Frequently Asked Questions</div>

        <div className='homepagehub__faq-items'>
          {faq.map((item, index) => (
            <div key={index} className='homepagehub__faq-item'>
              <div className='homepagehub__faq-item-question' onClick={() => handleFaqClick(item)}>
                {item.question}
              </div>

              {selectedFaq != null && selectedFaq.faq_question_id == item.faq_question_id && (
                <FadeIn delay={300} duration={300}>
                <div className='homepagehub__faq-item-answer'>
                  {item.answer}
                </div>
                </FadeIn>
              )}
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
}

export default HomepageHub_Vendor;