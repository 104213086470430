import { Fa500Px, FaSearch, FaShoppingCart, FaMapMarkerAlt, FaMapPin, FaCheck, FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import './ManageRoles.css';
import {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { addBuyerRole, addSellerRole, addEventAttendeeRole, addEventHostRole, hRefreshToken } from '../../utils/api';
import { refresh, isAuthed, isBuyer, isSeller, isEventHost, isEventAttendee } from '../../services/auth.service';

function ManageRoles() {
  const navigate = useNavigate();
  const [enablingBuyer, setEnablingBuyer] = useState(false);
  const [enablingSeller, setEnablingSeller] = useState(false);
  const [enablingEventAttendee, setEnablingEventAttendee] = useState(false);
  const [enablingEventHost, setEnablingEventHost] = useState(false);
  
  async function handleEnableBuyer(){
    setEnablingBuyer(true);

    let res = await addBuyerRole();

    if (res.success){
      await hRefreshToken();
      window.location = '/dashboard/buyer';
    }

    else if (res.status === 403){
      await refresh();

      let res = await addBuyerRole();

      if (res.success){
        await hRefreshToken();
        window.location = '/dashboard/buyer';
      }
    }
  }
  
  async function handleEnableSeller(){
    setEnablingSeller(true);

    let res = await addSellerRole();

    if (res.success){
      await hRefreshToken();
      window.location = '/dashboard/seller';
    }

    else if (res.status === 403){
      await refresh();

      let res = await addSellerRole();

      if (res.success){
        await hRefreshToken();
        window.location = '/dashboard/seller';
      }
    }
  }

  async function handleEnableEventAttendee(){
    setEnablingEventAttendee(true);

    let res = await addEventAttendeeRole();

    if (res.success){
      await hRefreshToken();
      window.location = '/events';
    }

    else if (res.status === 403){
      await refresh();

      let res = await addEventAttendeeRole();

      if (res.success){
        await hRefreshToken();
        window.location = '/events';
      }
    }
  }

  async function handleEnableEventHost(){
    setEnablingEventHost(true);

    let res = await addEventHostRole();

    if (res.success){
      await hRefreshToken();
      window.location = '/account/events';
    }

    else if (res.status === 403){
      await refresh();

      let res = await addEventHostRole();

      if (res.success){
        await hRefreshToken();
        window.location = '/account/events';
      }
    }
  }

return (
<div className='manageroles'>
  <h1>Manage your roles</h1>
  <div className='manageroles__roles'>
    <div className='manageroles__role manageroles__role--search'>
      <div className='manageroles__role-icon'><FaSearch /></div>
      <div className='manageroles__role-info'>
        <div className='manageroles__role-name'>Discover Software</div>
        <div className='manageroles__role-description'>Browse through 100's of leading software products and book demos to see them in action.</div>
      </div>

      <div className='manageroles__role-toggle'>
        {isBuyer() == true && (
          <div>
            <div className='manageroles__role-check'><FaCheckCircle /></div>
            <div><a className='btn manageroles__btn-dashboard' href='/dashboard/buyer'>Go to dashboard</a></div>
          </div>
        )}

        {isBuyer() == false && (
          <div className='center'>
            {enablingBuyer == false && (
              <button className='btn manageroles__btn-enable' onClick={() => handleEnableBuyer()}>Enable</button>
            )}

            {enablingBuyer == true && (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>

    <div className='manageroles__role manageroles__role--sell'>
      <div className='manageroles__role-icon'><FaShoppingCart /></div>
      <div className='manageroles__role-info'>
        <div className='manageroles__role-name'>Sell Software</div>
        <div className='manageroles__role-description'>List your company in our software directory and have interested candidates book demos directly with you.</div>
      </div>

      <div className='manageroles__role-toggle'>
        {isSeller() == true && (
          <div>
            <div className='manageroles__role-check'><FaCheckCircle /></div>
            <div><a className='btn manageroles__btn-dashboard' href='/dashboard/seller'>Go to dashboard</a></div>
          </div>
        )}

        {isSeller() == false && (
          <div className='center'>
            {enablingSeller == false && (
              <button className='btn manageroles__btn-enable' onClick={() => handleEnableSeller()}>Enable</button>
            )}

            {enablingSeller == true && (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>

    <div className='manageroles__role manageroles__role--attend'>
      <div className='manageroles__role-icon'><FaMapMarkerAlt /></div>

      <div className='manageroles__role-info'>
        <div className='manageroles__role-name'>Attend Events</div>
        <div className='manageroles__role-description'>Register and attend local events in your area. Download our free iOS and Android app and chat with guests before the event starts.</div>
      </div>

      <div className='manageroles__role-toggle'>
      {isEventAttendee() == true && (
          <div>
            <div className='manageroles__role-check'><FaCheckCircle /></div>
            <div><a className='btn manageroles__btn-dashboard' href='/events'>Go to dashboard</a></div>
          </div>
        )}

        {isEventAttendee() == false && (
          <div className='center'>
            {enablingEventAttendee == false && (
              <button className='btn manageroles__btn-enable' onClick={() => handleEnableEventAttendee()}>Enable</button>
            )}

            {enablingEventAttendee == true && (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>

    <div className='manageroles__role manageroles__role--host'>
      <div className='manageroles__role-icon'><FaMapPin /></div>
      <div className='manageroles__role-info'>
        <div className='manageroles__role-name'>Host Events</div>
        <div className='manageroles__role-description'>Create and manage local events in our intuitive interface and add them to our directory for free.</div>
      </div>

      <div className='manageroles__role-toggle'>
        {isEventHost() == true && (
          <div>
            <div className='manageroles__role-check'><FaCheckCircle /></div>
            <div><a className='btn manageroles__btn-dashboard' href='/account/events'>Go to dashboard</a></div>
          </div>
        )}

        {isEventHost() == false && (
          <div className='center'>
            {enablingEventHost == false && (
              <button className='btn manageroles__btn-enable' onClick={() => handleEnableEventHost()}>Enable</button>
            )}

            {enablingEventHost == true && (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>
  </div>
</div>
)
   }

   export default ManageRoles;