import './PaymentMethod.css';
import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { getCustomerId, createSetupIntent, checkHasPaymentMethod, getPaymentMethods, removePaymentMethod, refreshToken} from '../../utils/api';
import {Helmet} from "react-helmet";
import AddPaymentMethod from '../payment/AddPaymentMethod';
import Spinner from '../../components/layout/Spinner';
import mc from '../../assets/design/payments/mastercard.png';
import visa from '../../assets/design/payments/visa.png';

const PaymentMethod = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadPaymentMethod(){
      let res = await checkHasPaymentMethod();

      if (res.success){
        setHasPaymentMethod(res.data);
      }
    }

    async function loadPaymentMethods(){
      let res = await getPaymentMethods();
      
      if (res.success){
        setPaymentMethods(res.data);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getPaymentMethods();
      
          if (res.success){
            setPaymentMethods(res.data);
          }
        }
      }

      setLoaded(true);
    }

    loadPaymentMethod();
    loadPaymentMethods();
      document.title = "LeadrPro - Manage Payment Methods";
  }, []);

  async function handleRemoveMethod(){
    let res = await removePaymentMethod(selectedPaymentMethodId);

    if (res.success){
      window.location = window.location;
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await removePaymentMethod(selectedPaymentMethodId);

        if (res.success){
          window.location = window.location;
        }
      }
    }

    setShowConfirm(false);
  }

  async function handleRemoveClick(payment_method_id){
    setShowConfirm(true);
    setSelectedPaymentMethodId(payment_method_id);
  }

  return (
    <div className="payoutinfo">
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className=""><Link className='btn' to="/account/home">Back</Link></div>
      <div className="account__header">Payment Methods</div>

      {loaded === false && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {loaded && (
        <>
        {paymentMethods.length > 0 && (
          <>
            <p style={{marginTop: "30px"}}>
              You have a valid payment method on file. Note that you will only be charged once you have successfully completed a demo and have received final feedback.
            </p>

          <div className='paymentmethod__methods'>
            {paymentMethods.map(item => (
              <div className='paymentmethod__method'>
                <div className='paymentmethod__brand-icon'>
                  {item.card.brand === 'visa' && (
                    <img src={visa} alt='' />
                  )}

                  {item.card.brand === 'mastercard' && (
                    <img src={mc} alt='' />
                  )}
                </div>
                <div className='paymentmethod__brand'>{item.card.brand}</div>
                <div className='paymentmethod__last4'>&middot;&middot;&middot; &middot;&middot;&middot; {item.card.last4}</div>
                <div className='paymentmethod__month'><strong>Exp. </strong>{item.card.exp_month} / {item.card.exp_year}</div>
                <div><span className='link' onClick={() => handleRemoveClick(item.id)}>Remove</span></div>
              </div>
            ))}
          </div>
          </>
        )}

        {paymentMethods.length === 0 && (
          <>
            <p style={{marginTop: "30px"}}>
              You must have a valid payment method on file in order to continue to book demos. Note that you will only be charged once you have successfully completed a demo and have received final feedback.
            </p>

            <AddPaymentMethod />
          </>
        )}
      </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2>Are you sure you want to remove this payment method?</h2>

            <p>Note that you will not be able to take on more demos until a valid payment method has been set.</p>

            <p style={{marginTop: "30px"}}>
              <button className='btn btn--primary btn--active' onClick={() => handleRemoveMethod()}>Confirm</button>
              <span className='link' style={{marginLeft: "20px"}} onClick={() => setShowConfirm(false)}>Cancel</span>
            </p>
          </div>
        </>
      )}
    </div>
  )
}

  export default PaymentMethod;