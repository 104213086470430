import './AdminDashboard.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminCheck, indexCompany, getCompaniesForSitemap, getAdminDemosPending } from '../../utils/api';
import { refresh, isAdmin, getCurrentUser } from '../../services/auth.service';
import { FaClipboard, FaCog, FaCoins, FaElementor, FaEllipsisH, FaEllipsisV, FaHome, FaMailBulk, FaMobile, FaMobileAlt, FaPhoneAlt, FaRegBuilding, FaRegCalendarCheck, FaRegChartBar, FaRegCheckSquare, FaRegClipboard, FaRegComments, FaRegEdit, FaRegEnvelopeOpen, FaRegEye, FaRegFolder, FaRegQuestionCircle, FaRegSave, FaRegUser, FaSearch, FaShapes, FaSpinner, FaTicketAlt, FaUser, FaWrench } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FadeIn from '../../utils/fadein';
import queryString from 'query-string';
import DemosPending from './DemosPending';
import DemosUpcoming from './DemosUpcoming';
import CompanyIndexing from './CompanyIndexing';
import DemoRequests from './DemoRequests';
import PageViews from './PageViews';
import Companies from './Companies';
import Addons from './Addons';
import EventIndexing from './EventIndexing';
import CompanyLookup from './CompanyLookup';
import Users from './Users';
import Payouts from './Payouts';
import ActiveSellers from './ActiveSellers';
import CompanySaves from './CompanySaves';
import UserWaitlist from './UserWaitlist';
import ContactMessages from './ContactMessages';
import FeedbackMessages from './FeedbackMessages';
import ChannelTracking from './ChannelTracking';
import SIDTracking from './SIDTracking';
import Home from './Home';
import MonthlyReport from './MonthlyReport';
import SearchQueries from './SearchQueries';
import AppUsers from './AppUsers';
import Categories from './Categories';
import Faq from './Faq';
import CompanyClicks from './CompanyClicks';
import DemoLookup from './DemoLookup';
import PayNowCompanies from './PayNowCompanies';
import Actions from './Actions';
import MailOutbox from './MailOutbox';
import MailCampaign from './MailCampaign';
import SellerUsers from './Sellers';
import Buyers from './Buyers';
import BuyersWithDate from './BuyersWithDate';
import EventUsers from './EventUsers';
import DemoBreakdown from './DemoBreakdown';
import CompanyCredits from './CompanyCredits';
import PointActivity from './PointActivity';
import PointRewards from './PointRewards';
import UserPointActivity from './UserPointActivity';
import UserPointRewards from './UserPointRewards';
import PointLedger from './PointLedger';
import EventBreakdown from './EventBreakdown';
import MostActiveBuyers from './MostActiveBuyers';

function AdminDashboard() {
  const [loaded, setLoaded] = useState(false);
  const [checkLoaded, setCheckLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [indexable, setIndexable] = useState([]);
  const [sellersLoaded, setSellersLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sitemap, setSitemap] = useState('');
  const [sitemapGenerated, setSitemapGenerated] = useState(false);
  const [copied,setCopied] = useState(false);
  const [sitemapCount, setSitemapCount] = useState(0);
  const [isRoot, setIsRoot] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const currentUser = getCurrentUser();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function checkAdmin(){
      let res = await adminCheck();

      if (res.success){
        if (res.data == 0)
          window.location = '/';

        setCheckLoaded(true);

        if (currentUser.registered_user_id === 905 || currentUser.registered_user_id === 906 || currentUser.registered_user_id == 1){
          setIsRoot(true);
        }
      }

      else if (res.status == 403){
        await refresh();

        let res = await adminCheck();

        if (res.success){
          if (res.data == 0)
            window.location = '/';

          setCheckLoaded(true);
          if (currentUser.registered_user_id === 905 || currentUser.registered_user_id === 906 || currentUser.registered_user_id == 1){
            setIsRoot(true);
          }
        }
      }
    }

    checkAdmin();
  }, [currentTab]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    let tab = parsed['t'];

    if (tab && tab.length > 0)
      setCurrentTab(tab);
  }, []);

  function replaceQueryParam(param, newValue) {
    // Create a URL object from the current page URL
    const url = new URL(window.location.href);
  
    // Access the URL's query string parameters
    const params = url.searchParams;
  
    // Set (or replace) the parameter
    params.set(param, newValue);
  
    // Update the URL in the address bar without reloading the page
    window.history.replaceState({}, '', `${url.pathname}?${params}`);
  }

  async function handleSearch(query){
    setSearchQuery(query);
  }

async function handleCurrentTab(id){
  setCurrentTab(id);
  replaceQueryParam('t', id);
}

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      {checkLoaded == false && (
        <Spinner />
      )}

      {checkLoaded && (
      <div className='admindashboard'>
        <div className='admindashboard__tabs'>
          <div className={`admindashboard__tab ${currentTab === 12 ? ' selected': ''}`} onClick={() => handleCurrentTab(12)}><FaSearch /> Company Lookup</div>

          <div className={`admindashboard__tab ${currentTab === 10 ? ' selected': ''}`} onClick={() => handleCurrentTab(10)}><FaCoins /> Payouts</div>

          <div className={`admindashboard__tab ${currentTab === 'buyersdate' ? ' selected': ''}`} onClick={() => handleCurrentTab('buyersdate')}><FaRegUser /> Buyers w/ date</div>

          <div className={`admindashboard__tab ${currentTab === 'eventusers' ? ' selected': ''}`} onClick={() => handleCurrentTab('eventusers')}><FaRegUser /> Event Users</div>

          <div className={`admindashboard__tab ${currentTab === 25 ? ' selected': ''}`} onClick={() => handleCurrentTab(25)}><FaRegEnvelopeOpen /> Mail Campaigns</div>

          <div className={`admindashboard__tab ${currentTab === 13 ? ' selected': ''}`} onClick={() => handleCurrentTab(13)}><FaRegUser /> Users</div>

          <div className={`admindashboard__tab ${currentTab === 'seller_users' ? ' selected': ''}`} onClick={() => handleCurrentTab('seller_users')}><FaRegUser /> Sellers</div>

          <div className={`admindashboard__tab ${currentTab === 'buyers' ? ' selected': ''}`} onClick={() => handleCurrentTab('buyers')}><FaRegUser /> Buyers</div>

          <div className={`admindashboard__tab ${currentTab === 'demo_breakdown' ? ' selected': ''}`} onClick={() => handleCurrentTab('demo_breakdown')}><FaRegChartBar /> Demo Breakdown</div>

          <div className={`admindashboard__tab ${currentTab === 'company_credits' ? ' selected': ''}`} onClick={() => handleCurrentTab('company_credits')}><FaCoins /> Company Credits</div>

          <hr />

          <div className={`admindashboard__tab ${currentTab === 'event_breakdown' ? ' selected': ''}`} onClick={() => handleCurrentTab('event_breakdown')}><FaCoins /> Event Breakdown</div>

          <hr/>

          <div className={`admindashboard__tab ${currentTab === 'point_activity' ? ' selected': ''}`} onClick={() => handleCurrentTab('point_activity')}><FaCoins /> Point Activity</div>

          <div className={`admindashboard__tab ${currentTab === 'point_rewards' ? ' selected': ''}`} onClick={() => handleCurrentTab('point_rewards')}><FaCoins /> Point Rewards</div>

          <div className={`admindashboard__tab ${currentTab === 'user_point_activity' ? ' selected': ''}`} onClick={() => handleCurrentTab('user_point_activity')}><FaCoins /> User Point Activity</div>

          <div className={`admindashboard__tab ${currentTab === 'user_point_rewards' ? ' selected': ''}`} onClick={() => handleCurrentTab('user_point_rewards')}><FaCoins /> User Point Rewards</div>

          <div className={`admindashboard__tab ${currentTab === 'point_ledger' ? ' selected': ''}`} onClick={() => handleCurrentTab('point_ledger')}><FaCoins /> Point Ledger</div>

          <div className={`admindashboard__tab ${currentTab === 'active_buyers' ? ' selected': ''}`} onClick={() => handleCurrentTab('active_buyers')}><FaCoins /> Most Active Buyers</div>
          <hr />

          {isRoot && (
            <>
          <div className={`admindashboard__tab ${currentTab === 0 ? ' selected': ''}`} onClick={() => handleCurrentTab(0)}><FaHome /> Home</div>

          <div className={`admindashboard__tab ${currentTab === 19 ? ' selected': ''}`} onClick={() => handleCurrentTab(19)}><FaRegClipboard /> Monthly Report</div>

          <div className={`admindashboard__tab ${currentTab === 1 ? ' selected': ''}`} onClick={() => handleCurrentTab(1)}><FaRegBuilding /> Companies</div>

          <div className={`admindashboard__tab ${currentTab === 'pay_now' ? ' selected': ''}`} onClick={() => handleCurrentTab('pay_now')}><FaRegBuilding /> Companies - Pay Now</div>

          <div className={`admindashboard__tab ${currentTab === 2 ? ' selected': ''}`} onClick={() => handleCurrentTab(2)}><FaRegEye /> Views</div>

          <div className={`admindashboard__tab ${currentTab === 20 ? ' selected': ''}`} onClick={() => handleCurrentTab(20)}><FaRegEye /> Search Queries</div>

          <div className={`admindashboard__tab ${currentTab === 21 ? ' selected': ''}`} onClick={() => handleCurrentTab(21)}><FaMobileAlt /> App Users</div>

          <div className={`admindashboard__tab ${currentTab === 22 ? ' selected': ''}`} onClick={() => handleCurrentTab(22)}><FaRegFolder /> Categories</div>
          </>
          )}

          {isRoot && (
            <div className={`admindashboard__tab ${currentTab === 3 ? ' selected': ''}`} onClick={() => handleCurrentTab(3)}><FaSearch /> Company Indexing</div>
          )}

          {isRoot && (
            <div className={`admindashboard__tab ${currentTab === 11 ? ' selected': ''}`} onClick={() => handleCurrentTab(11)}><FaTicketAlt /> Event Indexing</div>
          )}

          {isRoot && (
            <div className={`admindashboard__tab ${currentTab === 4 ? ' selected': ''}`} onClick={() => handleCurrentTab(4)}><FaCog /> Actions</div>
          )}

          {isRoot && (
            <>
            <div className={`admindashboard__tab ${currentTab === 5 ? ' selected': ''}`} onClick={() => handleCurrentTab(5)}><FaTicketAlt /> Tickets</div>

            <div className={`admindashboard__tab ${currentTab === 6 ? ' selected': ''}`} onClick={() => handleCurrentTab(6)}><FaRegEdit /> Applications</div>

            <div className={`admindashboard__tab ${currentTab === 7 ? ' selected': ''}`} onClick={() => handleCurrentTab(7)}><FaRegCalendarCheck /> Upcoming</div>

            <div className={`admindashboard__tab ${currentTab === 8 ? ' selected': ''}`} onClick={() => handleCurrentTab(8)}><FaMailBulk /> Requests</div>

            <div className={`admindashboard__tab ${currentTab === 9 ? ' selected': ''}`} onClick={() => handleCurrentTab(9)}><FaShapes /> Addons</div>



            <div className={`admindashboard__tab ${currentTab === 'demo_lookup' ? ' selected': ''}`} onClick={() => handleCurrentTab('demo_lookup')}><FaSearch /> Demo Lookup</div>

            <div className={`admindashboard__tab ${currentTab === 14 ? ' selected': ''}`} onClick={() => handleCurrentTab(14)}><FaRegBuilding /> Active Sellers</div>

            <div className={`admindashboard__tab ${currentTab === 15 ? ' selected': ''}`} onClick={() => handleCurrentTab(15)}><FaRegSave /> Company Saves</div>

            <div className={`admindashboard__tab ${currentTab === 16 ? ' selected': ''}`} onClick={() => handleCurrentTab(16)}><FaRegCheckSquare /> Company Waitlist</div>

            <div className={`admindashboard__tab ${currentTab === 17 ? ' selected': ''}`} onClick={() => handleCurrentTab(17)}><FaRegEnvelopeOpen /> Contact Form</div>

            <div className={`admindashboard__tab ${currentTab === 18 ? ' selected': ''}`} onClick={() => handleCurrentTab(18)}><FaRegComments /> Feedback Messages</div>

            <div className={`admindashboard__tab ${currentTab === 23 ? ' selected': ''}`} onClick={() => handleCurrentTab(23)}><FaRegQuestionCircle /> FAQ</div>

            <div className={`admindashboard__tab ${currentTab === 24 ? ' selected': ''}`} onClick={() => handleCurrentTab(24)}><FaRegQuestionCircle /> Company Clicks</div>
            </>
          )}
        </div>

        <div className='admindashboard__content'>
        {currentTab == 0 && (
          <Home />
        )}

        {currentTab == 1 && (
          <Companies />
        )}

        {currentTab == 2 && (
          <PageViews />
        )}

        {currentTab == 3 && (
          <div>
            <CompanyIndexing />
          </div>
        )}

        {currentTab == 11 && (
          <div>
            <EventIndexing />
          </div>
        )}

        {currentTab == 4 && (
          <>
          <div>
            <Actions />
          </div>
          </>
        )}

      {currentTab == 6 && (
        <>
          <DemosPending />
        </>
      )}

      {currentTab == 7 && (
        <>
          <DemosUpcoming />
        </>
      )}

      {currentTab == 8 && (
        <>
          <DemoRequests />
        </>
      )}

      {currentTab == 9 && (
        <>
          <Addons />
        </>
      )}

      {currentTab == 10 && (
        <>
          <Payouts />
        </>
      )}

      {currentTab == 12 && (
        <CompanyLookup setCurrentTab={setCurrentTab} setSelectedCampaignId={setSelectedCampaignId} />
      )}

      {currentTab == 13 && (
        <>
         <Users />
        </>
      )}

      {currentTab == 14 && (
        <>
          <ActiveSellers />
        </>
      )}

      {currentTab == 15 && (
        <>
          <CompanySaves />
        </>
      )}

      {currentTab == 16 && (
        <>
          <UserWaitlist />
        </>
      )}

      {currentTab == 17 && (
        <>
          <ContactMessages />
        </>
      )}

      {currentTab == 18 && (
        <>
          <FeedbackMessages />
        </>
      )}

      {currentTab == 19 && (
        <>
          <MonthlyReport />
        </>
      )}

      {currentTab == 20 && (
        <SearchQueries />
      )}

      {currentTab == 21 && (
        <AppUsers />
      )}

      {currentTab == 22 && (
        <Categories />
      )}

      {currentTab == 23 && (
        <Faq />
      )}

      {currentTab == 24 && (
        <CompanyClicks />
      )}

      {currentTab == 25 && (
        <MailOutbox />
      )}

      {currentTab == 26 && (
        <MailCampaign campaignId={selectedCampaignId} />
      )}

      {currentTab == 'demo_lookup' && (
        <DemoLookup />
      )}

      {currentTab == 'pay_now' && (
        <PayNowCompanies />
      )}

      {currentTab == 'seller_users' && (
        <SellerUsers />
      )}

      {currentTab == 'buyers' && (
        <Buyers />
      )}

      {currentTab == 'buyersdate' && (
        <BuyersWithDate />
      )}

      {currentTab == 'eventusers' && (
        <EventUsers />
      )}

      {currentTab == 'demo_breakdown' && (
        <DemoBreakdown />
      )}

      {currentTab == 'company_credits' && (
        <CompanyCredits />
      )}

      {currentTab == 'point_activity' && (
        <PointActivity />
      )}

      {currentTab == 'point_rewards' && (
        <PointRewards />
      )}

      {currentTab == 'user_point_activity' && (
        <UserPointActivity />
      )}

      {currentTab == 'user_point_rewards' && (
        <UserPointRewards />
      )}

      {currentTab == 'point_ledger' && (
        <PointLedger />
      )}

      {currentTab == 'event_breakdown' && (
        <EventBreakdown />
      )}

{currentTab == 'active_buyers' && (
        <MostActiveBuyers />
      )}

      </div>
      </div>
      )}
    </>
  );
}

export default AdminDashboard;
;
