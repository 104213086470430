import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { adminUpdateCompany } from '../../utils/api';
import { markProfileAsReviewed, sendInitialCampaign, markCompanyAsPublished,
  sendNextStepsEmail, sendNextStepsEmailAlt, skipNextStepsEmail,
   confirmMeetingScheduled, getMailOutboxEmais, getAllMailOutboxEmails, sendInitialCampaignTest,
   skipInitialCampaign, getCompaniesByDataset, lookupDemos, adminSearchCompany,
   addCompanyCategory, getCategoriesWithCompanies
 } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import queryString from 'query-string';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEyeDropper, FaRegCheckCircle, FaTimes } from 'react-icons/fa';
import './CompanyLookup.css';

function CompanyLookup({setCurrentTab, setSelectedCampaignId}) {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showConfirmSendEmail, setShowConfirmSendEmail] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [showEmails, setShowEmails] = useState(false);
  const [emails, setEmails] = useState([]);
  const [demos, setDemos] = useState([]);
  const [showDemos, setShowDemos] = useState(false);
  const [enforceICP, setEnforceICP] = useState(false);
  const [showFromAdmin, setShowFromAdmin] = useState(false);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCategories(){
      let res = await getCategoriesWithCompanies();

      if (res.success){
        setCategories(res.data);
      }
    }

    loadCategories();
  }, []);
  async function handleSearch(){
    setSubmitting(true);
    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await adminSearchCompany(query, id);

    if (res.success){
      setResults(res.data);
      setSubmitting(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await adminSearchCompany(query, id);

      if (res.success){
        setResults(res.data);
        setSubmitting(false);
      }
    }
  }

  async function handleUpdateTagline(id){
    let data = {tagline: document.getElementById(`txtTagline_${id}`).value};
    let res = await adminUpdateCompany(id, data, 'Updating from admin');

    if (res.success){
      //await handleSearch();
      alert('Updated');
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminUpdateCompany(id, data, 'Updating from admin');

      if (res.success){
       // handleSearch();
       alert('Updated');
      }
    }
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      document.getElementById('selectDataset').value = '';
      await handleSearch();
    }
  }

  async function handleReviewed(company_id, item){
    let res = await markProfileAsReviewed(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handlePublished(company_id, item){
    let res = await markCompanyAsPublished(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleSendEmail(company_id, item){
    setShowConfirmSendEmail(true);
    setSelectedCompanyId(company_id);
  }

  async function handleSendTestEmail(company_id, item){
    let res = await sendInitialCampaignTest(company_id);
    alert('Sent');
  }

  async function handleSendEmailSkip(company_id){
    let res = await skipInitialCampaign(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleConfirmEmail(){
    setShowConfirmSendEmail(false);
    let res = await sendInitialCampaign(selectedCompanyId);

    if (res.success){
      handleSearch();
    }
  }

  async function handleNextStepsEmail(company_id){
    let res = await sendNextStepsEmail(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleNextStepsEmailAlt(company_id){
    let res = await sendNextStepsEmailAlt(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleSkipNextSteps(company_id){
    let res = await skipNextStepsEmail(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleConfirmMeeting(company_id){
    let res = await confirmMeetingScheduled(company_id);

    if (res.success){
      handleSearch();
    }
  }

  async function handleViewEmails(source_id){
    let res = await getAllMailOutboxEmails(source_id);

    if (res.success){
      setShowEmails(true);
      setEmails(res.data);
    }
  }

  async function handleDatasetChange(id){
    setSubmitting(true);
    setQuery('');
    let res = await getCompaniesByDataset(id);

    if (res.success){
      setResults(res.data);
      setSubmitting(false);
    }
  }

  async function handleViewDemos(company_id){
    let res = await lookupDemos(company_id);

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }
  }

  async function handleEnforceICP(val, id){
   // setEnforceICP(val);

    let res = await adminUpdateCompany(id, {enforce_icp: val});

    if (res.success){
      handleSearch();
    }
  }

  async function handlePutOnDrip(id){
    let res = await adminUpdateCompany(id, {is_on_drip: true});

    if (res.success){
      handleSearch();
    }
  }

  async function handleDisableDrip(id){
    let res = await adminUpdateCompany(id, {is_on_drip: false});

    if (res.success){
      handleSearch();
    }
  }

  async function handleMarkInactive(id){
    let res = await adminUpdateCompany(id, {is_inactive: true});

    if (res.success){
      handleSearch();
    }
  }

  async function handleTaglineChange(e, company_id){
    const newTagline = e.target.value;
    setResults((prevItems) =>
      prevItems.map((item) =>
        item.company_id === company_id ? { ...item, tagline: newTagline } : item
      )
    );
  }

  async function handleAddCategory(){

  }

  async function handleCategoryKeyup(val){

  }

  return (
    <div className='companylookup'>
      <Helmet>
        <title>Admin - Company Lookup</title>
      </Helmet>

      <h1>Company Lookup</h1>

      <div className='admindashboard__search-lookup'>
        <div>
        <select id='selectDataset' className='select input' onChange={(e) => handleDatasetChange(e.target.value)}>
          <option>Select Dataset</option>
          <option value={1}>New seller companies - past 30 days</option>
          <option value={2}>New seller companies - past 60 days</option>
          <option value={3}>"Pay Later" Sellers</option>
          <option value={4}>"Pay Now" Sellers</option>
        </select>
        </div>

        <div className='flex ml-10'>
          <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />

          <div className='admindashboard__search-lookup-btn'>
            <button className='btn' onClick={() => handleSearch()}>Search</button>
          </div>
        </div>
      </div>

      <div className='mt-10'>
        <div>
          <input type='checkbox' checked={showFromAdmin} onChange={(e) => setShowFromAdmin(e.target.checked)} ></input> <label>Show From Admin</label>
        </div>
      </div>

      {submitting === false && loaded && results.length === 0 && (
        <div className="center mt-20"><strong>Nothing to see here...</strong></div>
      )}

      {submitting === true && (
        <Spinner />
      )}

      {submitting === false && (
         <div>
          <div style={{fontWeight: 'bold', marginBottom: '10px', fontSize: '14px'}}>
            Results found: {results.length}
          </div>

          {results.length > 0 && (
            <div>
              {results.filter(x => (showFromAdmin == false && x.self_signup == true) || showFromAdmin == true).map((item, index) => (
                <div className='companylookup__company'>
                  {item.self_signup == true && (
                  <div className='companylookup__company-self-signup admindashboard__green-flag'>
                    Self-Signup
                  </div>
                  )}

                  {item.self_signup == false && (
                    <div className='companylookup__company-self-signup admindashboard__red-flag'>
                        From Admin
                    </div>
                  )}

                  <div className='companylookup__company-header'>
                    <div style={{fontSize: '12px', marginBottom: '10px'}}>
                      Date Joined: {new Date(item.date_created).toDateString()}
                    </div>
                  </div>

                  <div className='companylookup__company-status-bar'>
                    <div className='companylookup__company-status-bar-item success'>
                      <span>New</span> <span><FaRegCheckCircle /></span>
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.profile_reviewed_by_admin ? 'success': ''}`}>
                      <span>Profile reviewed</span>

                      {item.profile_reviewed_by_admin && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.is_seller ? 'success': ''}`}>
                     <span>Live on search</span>

                     {item.is_seller && (
                      <span><FaRegCheckCircle /></span>
                     )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.initial_campaign_sent ? 'success': ''}`}>
                      <span>Initial email campaign sent</span>

                      {item.initial_campaign_sent && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.next_steps_email_sent ? 'success': ''} ${item.next_steps_email_skipped ? 'skipped': ''}`}>
                      <span>"Next steps" email sent</span>

                      {item.next_steps_email_sent && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.sales_meeting_scheduled ? 'success': ''}`}>
                      <span>Intro meeting scheduled</span>

                      {item.sales_meeting_scheduled && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.demos_approved != '0' ? 'success': ''}`}>
                      <span>First demo booked</span>

                      {item.demos_approved != '0' && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>

                    <div className={`companylookup__company-status-bar-item ${item.is_on_drip == true ? 'success': ''}`}>
                      <span>On drip <FaEyeDropper /></span>

                      {item.is_on_drip == true && (
                        <span><FaRegCheckCircle /></span>
                      )}
                    </div>
                  </div>

                  <div className='flex' style={{flexDirection: 'row-reverse'}}>
                  <div className='companylookup__status-items'>
                    <div className='companylookup__status'>
                      <h3>Tagline</h3>
                      
                      <div>
                        <textarea style={{minHeight: '100px'}} value={item.tagline} type='text' placeholder='Enter tagline'
                        onChange={(e) => handleTaglineChange(e, item.company_id)} className='input' id={`txtTagline_${item.company_id}`}></textarea>

                        <span className='link' onClick={(e) => handleUpdateTagline(item.company_id)}>Update</span>
                      </div>
                    </div>

                  {item.is_seller === false && (
                  <div className='companylookup__status'>
                    <h3>This company is not yet published.</h3>

                    <p className='companylookup__status-copy'>
                      This company does not yet appear on the search results page.
                    </p>

                    <div className='mt-10'><button className='btn' onClick={() => handlePublished(item.company_id)}>Set to published</button></div>
                  </div>
                  )}

                  {item.profile_reviewed_by_admin === false && (
                  <div className='companylookup__status'>
                    <h3>Company profile <strong>NOT</strong> reviewed</h3>

                    <p className='companylookup__status-copy'>This company profile has not been reviewed by an admin.</p>

                    <p>
                      <Link className='link' target='_blank' to={`/company/${item.company_id}/profile`}>View company profile</Link>
                    </p>

                    <p>
                      <a className='link' target='_blank' href={`https://admin01.leadrpro.com/company/${item.company_id}`}>Edit company profile</a>
                    </p>

                    <div className='mt-10'><button className='btn' onClick={() => handleReviewed(item.company_id)}>Mark as reviewed</button></div>
                  </div>
                  )}

                  {item.initial_campaign_sent == false && item.is_seller && (
                  <div className='companylookup__status'>
                    <h3>Send "new seller" email to active buyers</h3>

                    <p className='companylookup__status-copy'>Invite the top active buyers to apply for this new company.</p>

                    <div className='mt-10'><button className='btn' onClick={() => handleSendEmail(item.company_id)}>Send email</button>

                    <button className='btn ml-10' onClick={() => handleSendEmailSkip(item.company_id)}>Skip email send</button>
                    
                    <span className='ml-10 btn' onClick={() => handleSendTestEmail(item.company_id)}>
                          Send test email
                        </span>
                    </div>
                  </div>
                  )}

                  {item.next_steps_email_sent == false && item.is_seller && item.initial_campaign_sent === true && item.next_steps_email_skipped == false && (
                  <div className='companylookup__status'>
                    <h3>"Next Steps" email to seller</h3>

                    <p className='companylookup__status-copy'>Send "Next steps" email to seller after they have received 2 demo applications.</p>

                    <div className='mt-10'>
                      <button className='btn' onClick={() => handleNextStepsEmail(item.company_id)}>Send email</button>
                      
                      <button className='btn ml-10' onClick={() => handleNextStepsEmailAlt(item.company_id)}>Already sent</button>

                      <button className='btn ml-10' onClick={() => handleSkipNextSteps(item.company_id)}>
                        Skip send
                      </button>
                      </div>
                  </div>
                  )}

                {(item.next_steps_email_sent == true || item.next_steps_email_skipped == true) && item.sales_meeting_scheduled === false && (
                  <div className='companylookup__status'>
                    <h3>Sales meeting scheduled?</h3>

                    <p className='companylookup__status-copy'>Sales meeting successfully scheduled?</p>

                    <div className='mt-10'><button className='btn' onClick={() => handleConfirmMeeting(item.company_id)}>Confirm meeting scheduled</button></div>
                  </div>
                  )}

                  {item.initial_campaign_sent == true && (
                    <div className='companylookup__status'>
                      <h3>Initial email campaign sent <FaRegCheckCircle /></h3>

                      <p className='companylookup__status-copy'>
                        New seller invite email has been sent to active buyers.
                      </p>

                      <div className='mt-10 flex'>
                        {/* <span onClick={() => {
                          handleViewEmails(item.company_id)
                        }} className='btn'>View email campaign tracking</span> */}

                        <span className='ml-10 btn' onClick={() => handleSendEmail(item.company_id)}>
                          Send another campaign
                        </span>

                        <span className='ml-10 btn' onClick={() => handleSendTestEmail(item.company_id)}>
                          Send test email
                        </span>
                      </div>
                    </div>
                  )}

                  {(item.next_steps_email_sent == true || item.next_steps_email_skipped == true) && item.initial_campaign_sent === true && (
                  <div className='companylookup__status'>
                    <h3>Invite buyers to demo</h3>

                    <p className='companylookup__status-copy'>Send standard daily invite emails to active buyers.</p>

                    <div className='mt-10'><button className='btn' onClick={() => handleConfirmMeeting(item.company_id)}>Send email invites</button></div>
                  </div>
                  )}

                  {item.is_on_drip == false && (
                  <div className='companylookup__status'>
                    <h3>Enable Drip Campaign <span className=''><FaEyeDropper /></span></h3>

                    <p className='companylookup__status-copy'>Send daily campaigns to seller recommending demo candidates</p>

                    <div className='mt-10'>
                      <button className='btn' onClick={() => handlePutOnDrip(item.company_id)}>Put on Drip Campaign</button>
                    </div>
                  </div>
                  )}

                  {item.is_on_drip == true && (
                  <div className='companylookup__status'>
                    <h3>Disable Drip Campaign <span className=''><FaEyeDropper /></span></h3>

                    <p className='companylookup__status-copy'>Send daily campaigns to seller recommending demo candidates</p>

                    <div className='mt-10'>
                      <button className='btn' onClick={() => handleDisableDrip(item.company_id)}>Disable Drip Campaign</button>
                    </div>
                  </div>
                  )}

                  <div className='companylookup__status'>
                    <h3>Company tags</h3>

                    <div className='companylookup__tags'>
                      {item.categories.map(c => (
                        <div>
                          {c.name}
                        </div>
                      ))}
                    </div>

                    {item.categories.length == 0 && (
                      <strong>No categories found...</strong>
                    )}

                    <div className='mt-10'>
                      <input type='text' onKeyUp={(e) => handleCategoryKeyup(e.target.value)} value={category} onChange={(e) => setCategory(e.target.value)} placeholder='Add category' className='input' />
                      
                      <div className='mt-10'><button className='btn' onClick={() => handleAddCategory()}>Add Category</button></div>
                    </div>
                  </div>

                  <div className='companylookup__status'>
                    <h3>ICP Company Sizes</h3>

                    <div className='companylookup__tags'>
                      <div>100</div>
                      <div>200-400</div>
                      <div>500-1000</div>
                      <div>1000+</div>
                    </div>
                  </div>
                  </div>

                  <div className='companylookup__table-wrapper'>
                    <div className='companylookup__company-card'>
                      <div className=''>
                      <div className='companylookup__company-card-logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                      </div>

                        <div className='companylookup__company-header-name ml-10'>#{item.company_id} - {item.name}</div>

                        <div className='mt-10'>
                          <div>Lifetime applications: {item.total_applications}</div>
                          <div>Pending applications: {item.demo_applications}</div>
                          <div>Approved: {item.demos_approved}</div>
                          <div>Completed: {item.demos_completed}</div>
                          <div>Declined: {item.demos_declined}</div>
                        </div>
                      </div>
                    </div> 

                  <table className='companylookup__table'>
                    <tr>
                      <td></td>

                      <td>
                        <div><Link className='link' to={`/company/${item.company_id}/profile`} target='_blank'>View public profile</Link></div>

                        <div>
                          <a className='link' href={`https://admin01.leadrpro.com/company/${item.company_id}`} target='_blank'>Edit profile</a>
                        </div>

                        <div>
                          <span className='link' onClick={() => handleViewDemos(item.company_id)}>
                            View demos
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Inactive</td>

                      <td>
                        {item.is_inactive == true ? <span className='admindashboard__green-flag'>Yes</span> : <span className='admindashboard__red-flag'>No</span>}
                      
                        {item.is_inactive == false && (
                          <div>
                            <span className='link' onClick={(e) => handleMarkInactive(item.company_id)}>Mark Inactive</span>
                          </div>
                        )}
                      </td>
                    </tr>

                    {item.last_demo_date && (
                    <tr>
                      <td>Last demo date</td>
                      <td>{new Date(item.last_demo_date).toLocaleDateString()}</td>
                    </tr>
                    )}

                    {item.last_viewed_lead && (
                    <tr>
                      <td>Last viewed lead Card</td>
                      <td>{new Date(item.last_viewed_lead).toLocaleDateString()}</td>
                    </tr>
                    )}

                    <tr>
                      <td>Industry</td>
                      <td>{item.industry}</td>
                    </tr>

                    <tr>
                      <td>Initial campaign emails sent</td>
                      <td><span className='link' onClick={() => {
                          handleViewEmails(item.company_id)
                        }}>{item.initial_campaign_emails_sent}</span></td>
                    </tr>

                    <tr>
                      <td>Enforce ICP settings</td>
                      <td>
                        <input type='checkbox' checked={item.enforce_icp} onChange={(e) => handleEnforceICP(e.target.checked, item.company_id)} />
                      </td>
                    </tr>

                    {/* <tr>
                      <td>Total applications</td>
                      <td>{item.total_applications}</td>
                    </tr>

                    <tr>
                      <td>Current applications</td>
                      <td>{item.demo_applications}</td>
                    </tr>

                    <tr>
                      <td>Approved</td>
                      <td>{item.demos_approved}</td>
                    </tr>

                    <tr>
                      <td>Declined</td>
                      <td>{item.demos_declined}</td>
                    </tr> */}

                    {/* <tr>
                      <td>Completed</td>
                      <td>{item.demos_completed}</td>
                    </tr> */}

                    <tr>
                      <td>Total credits purchased</td>
                      <td>{item.total_credits}</td>
                    </tr>

                    <tr>
                      <td>Current credits</td>
                      <td>{item.remaining_credits}</td>
                    </tr>

                    <tr>
                      <td>Website</td>
                      <td>{item.website}</td>
                    </tr>

                    <tr>
                      <td>URL Slug</td>
                      <td>{item.url_slug && item.url_slug.length > 0 ? item.url_slug : <i className='admindashboard__red-flag'>Not set</i>}</td>
                    </tr>

                    <tr>
                      <td>Is Indexed</td>
                      <td>
                        {item.is_indexed && (
                          <span>True</span>
                        )}

                        {item.is_indexed == false && (
                          <span>False</span>
                        )}
                      </td>
                    </tr>

                    {/* <tr>
                      <td>Tagline</td>
                      <td>
                        {item.tagline != null && item.tagline.length > 0 && (
                          <span>
                            {item.tagline}
                          </span>
                        )}

                        {(item.tagline == null || item.tagline.length == 0) && (
                          <div>
                            <input type='text' placeholder='Enter tagline' className='input' id={`txtTagline_${item.company_id}`} />

                            <span className='link' onClick={(e) => handleUpdateTagline(item.company_id)}>Update</span>
                          </div>
                        )}
                        </td>
                    </tr> */}

                    <tr>
                      <td>Payment type</td>
                      <td>{item.payment_type}</td>
                    </tr>

                    {/* <tr>
                      <td>Paused</td>
                      <td>{item.is_paused == true ? <span className='admindashboard__green-flag'>Yes</span> : <span className='admindashboard__red-flag'>No</span>}</td>
                    </tr> */}

                    {/* <tr>
                      <td>Live on search</td>
                      <td>{item.is_seller == true ? <span className='admindashboard__green-flag'>Yes</span> : <span className='admindashboard__red-flag'>No</span>}</td>
                    </tr> */}

                    <tr>
                      <td>Contact email</td>
                      <td>
                        {item.email == null && (
                          <i>Not set</i>
                        )}

                        {item.email != null && (
                          <i>{item.email}</i>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Country</td>

                      <td>
                        {item.country_name}
                      </td>
                    </tr>
                  </table>
                  </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {showConfirmSendEmail && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirmSendEmail(false)}>
              <FaTimes />
            </div>

            <h2>Are you sure that you want to send this initial campaign?</h2>

            <p className='mt-20'>
              An initial 200-email campaign will be sent to active buyers.
            </p>

            <div className='mt-20 center'>
              <button className='btn' onClick={() => handleConfirmEmail()}>Confirm and send</button>
            </div>
          </div>
        </>
      )}

      {showEmails && (
        <>
          <div className='overlay'></div>
          <div className='modal mailoutbox__modal'>
            <div className='modal__close' onClick={() => setShowEmails(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Mail Outbox</h2>

            <div className='flex'>
              <div><strong>Total sent: </strong> {emails.length}</div>
              <div className='ml-10'><strong>Total clicked: </strong> {emails.filter(x => x.status == 'Clicked').length}</div>
            </div>

            <div className='mt-10'>
              <table className='admindashboard__table table'>
                <th>Date sent</th>
                <th>Email</th>
                <th>Name</th>
                <th>Job title</th>
                <th>Company</th>
                <th></th>
                {emails.map(item => (
                  <tr>
                    <td>{new Date(item.date_created).toLocaleString()}</td>
                    <td>{item.email}</td>
                    <td>{item.first_name} {item.last_name}</td>
                    <td>{item.job_title}</td>

                    <td>{item.company_name}</td>

                    <td>
                    <div className={`admindashboard__demo-request-status ${item.status.toLowerCase()}`}>
                      {item.status}
                    </div>
                  </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </>
      )}

      {showDemos && (
        <>
        <div className='overlay'></div>
        <div className='modal companylookup__modal'>
          <div className='modal__close' onClick={() => setShowDemos(false)}>
            <FaTimes />
          </div>
          <h2 className='modal__h2'>Company Demos {`(${demos.length})`}</h2>

          <div className='companylookup__demos mt-10'>
            {demos.map(item => (
              <>
                <div className='companylookup__demo'>
                  <div className='companylookup_demo-date'>
                    <div><strong>Applied: </strong>{new Date(item.date_applied).toLocaleString()}</div>
                    <div>{new Date(item.demo_date).toLocaleString()}</div>
                  </div>

                  <div className='companylookup__demo-company'>
                    <div className=''>
                       <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} style={{maxHeight: '34px'}} />
                     </div>
                    <div className='admindashboard__demo-company-name'>{item.buyer_company_name}</div>
                  </div>

                  <div className='companylookup__demo-company'>
                    <div>
                      {item.buyer_status == 'Approved' && (
                        <span className='admindashboard__green-flag'>Approved</span>
                      )}

                      {item.buyer_status == 'Rescheduled' && (
                        <span className='admindashboard__blue-flag'>Rescheduled</span>
                      )}

                      {item.buyer_status == 'Declined' && (
                        <span className='admindashboard__red-flag'>Declined</span>
                      )}
                    </div>

                    {/* <div className=''>
                       <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} style={{maxHeight: '34px'}} />
                     </div>

                    <div className='admindashboard__demo-company-name'>{item.seller_company_name}</div> */}

                    <div>
                      {item.seller_status == 'Approved' && (
                        <span className='admindashboard__green-flag'>Approved</span>
                      )}

                      {item.seller_status == 'Pending' && (
                        <span className='admindashboard__yellow-flag'>Pending</span>
                      )}

                      {item.seller_status == 'Rescheduled' && (
                        <span className='admindashboard__blue-flag'>Rescheduled</span>
                      )}

                      {item.seller_status == 'Declined' && (
                        <span className='admindashboard__red-flag'>Declined</span>
                      )}
                    </div>

                    <div>
                      {item.demo_status == 'Completed' && (
                        <span className='admindashboard__green-flag'>Approved</span>
                      )}

                        {item.demo_status == 'Pending' && (
                        <span className='admindashboard__yellow-flag'>Pending</span>
                      )}

                      {item.demo_status == 'Declined' && (
                        <span className='admindashboard__red-flag'>Declined</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        </>
      )}
    </div>
  );
}

export default CompanyLookup;