import './ProfileCard.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';

const ProfileCard = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
        setSuccess(true);
      }

      else if (response.status === 403){
        await refresh();
        let response = await getUserProfile();

        if (response.success){
          setUser(response.data);
          setLoading(false);
          setSuccess(true);
        }
      }

      else{
        setLoading(false);
      }
    }

    loadUserProfile();
  }, []);

  return (
    <div className="profilecard">
      {loading && (
        <Spinner />
      )}

      {loading === false && success == true && (
        <>
      <div className='profilecard__image' onClick={() => navigate('/account/profile')}>
        {user.profile_image && user.profile_image !== '' && (
            <img src={baseImageUrl + 'profileimages/' + user.profile_image} alt="logo found" />
          )}

          {(!user.profile_image || user.profile_image === '') && (
            <img src={iconCompanyDefault} alt="logo not found" />
          )}
      </div>

      {loading === false && user.first_name && user.first_name.length > 0 && (
        <div className='profilecard__name'>
          <span>{user.first_name}{' '}{user.last_name}</span>
        </div>
      )}

      {loading === false && user.first_name && user.first_name.length === 0 && (
        <div className='center profilecard__edit-link'>
          <Link to='/dashboard/profile'><i>Edit your profile</i></Link>
        </div>
      )}

      {loading === false && (user.first_name == null || user.first_name.length == 0 && (
        <div className='center'>
          <Link to={'/account/profile'} className='center' style={{display: 'block'}}>
            <span className='link' style={{color: 'red', fontWeight: 'bold'}}>Fill out your name</span>
          </Link>
        </div>
      ))}

      <div className='profilecard__job-title'>
        {user.job_title} at {user.company_name}
      </div>

      {/* {isSeller() && (
      <div className='profilecard__company-link'>
        <Link className='' to={`/company/${user.company_id}`}>Your company profile</Link>
        </div>
      )} */}
      </>
      )}
    </div>
  )
}

  export default ProfileCard;